<template>
  <div>
    <template v-for="(obDiscount, index) in discounts">
      <form-field-text
        label="global.discount"
        input-type="number"
        :disabled="getDisabled(index)"
        :value="obDiscount"
        @input="setDiscount($event, Number(index))"
        :key="`customer-discounts-${index}`"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import { CompanySettingsData, DiscountData } from "@planetadeleste/vue-mc-gw";
import { Component, VModel, Vue } from "vue-property-decorator";

@Component
export default class DiscountFields extends Vue {
  @VModel({ type: Array, default: () => [] }) arDiscounts!:
    | DiscountData[]
    | Record<string, any>[];
  iMaxDiscounts = 3;

  getDisabled(iIdx: number | string): boolean {
    iIdx = Number(iIdx);

    if (!iIdx || iIdx === 0) {
      return false;
    }

    return !this.$_.get(this.arDiscounts, `${iIdx - 1}.value`);
  }

  setDiscount(sValue: string | number, index: number): void {
    const obDiscount = this.$_.get(this.arDiscounts, index, {});
    this.$_.set(obDiscount, "value", sValue);

    if (!this.arDiscounts.length || this.arDiscounts.length < index) {
      // @ts-ignore
      this.arDiscounts.push(obDiscount);
    } else {
      this.arDiscounts.splice(index, 1, obDiscount);
    }

    this.$emit("input", this.arDiscounts);
  }

  get discounts(): number[] | string[] {
    if (!this.maxDiscounts) {
      return [];
    }

    this.arDiscounts;
    const arItems = new Array(this.maxDiscounts);
    return this.$_.map(arItems, (item, index) => {
      return this.$_.get(this.arDiscounts, `${index}.value`, "");
    });
  }

  get company() {
    return AppModule.company;
  }

  get maxDiscounts() {
    const obSettings: CompanySettingsData = this.company.get("settings");
    if (!obSettings) {
      return 0;
    }

    return obSettings.max_global_discounts;
  }
}
</script>
